import React from 'react'
import { Link } from 'react-router-dom'
import './index.scss'

import ODlogo from '../../../assets/images/partners/OD-logo.png'
import MIALogo from '../../../assets/images/partners/MIA.jpeg'
import miyaLogo from '../../../assets/images/partners/miya-academy.png'
import edLogo from '../../../assets/images/partners/ed4free.png'
import yiLogo from '../../../assets/images/partners/youth-insp.png'

const Partners = () => {
  return (
    <section className="partners-section">
      <div>
        <h3 className="font-bold text-2xl text-center text-primary-alt">
          Our Partners
        </h3>
      </div>
      <div className="partners my-4">
        <div className="flex flex-row flex-wrap justify-around items-center">
          <Link
            to="https://www.open-dreams.org"
            target="_blank"
            className="partner"
          >
            <div className="flex flex-col m-6">
              <img className="w-40" src={ODlogo} alt="Logo" />
            </div>
          </Link>

          <Link
            to="https://www.facebook.com/youthsinspire/"
            target="_blank"
            className="partner"
          >
            <div className="flex flex-col m-6">
              <img className="w-40" src={yiLogo} alt="Logo" />
            </div>
          </Link>

          <Link
            to="https://moodlephone.org/"
            target="_blank"
            className="partner"
          >
            <div className="flex flex-col m-6">
              <img className="w-40" src={edLogo} alt="Logo" />
            </div>
          </Link>

          <Link
            to="https://www.facebook.com/MIA4all/"
            target="_blank"
            className="partner"
          >
            <div className="flex flex-col m-6">
              <img className="w-40" src={MIALogo} alt="Logo" />
            </div>
          </Link>

          <Link to="https://miya.academy/" target="_blank" className="partner">
            <div className="flex flex-col m-6">
              <img className="w-40" src={miyaLogo} alt="Logo" />
            </div>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Partners
