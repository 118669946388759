/**
 * About the app
 */

export const rootURL = window.location.origin || 'https://ketacademy.web.app'
export const actionCodeSettings = {
  url: `${rootURL}/dashboard`,
  handleCodeInApp: true,
}

//User permission

const permissions = {
  AMBASSADOR: 'Ambassador', // Ambassador & Facilitators
  ADMINISTRATOR: 'HOD and/or Ex-Co Member', //Head of Department
  STUDENT: 'Student',
  CORE: 'Core Member', //Core Members
}

const roles = ['SOCIAL_MEDIA_MANAGER', 'GRAPHIC_DESIGNER']

//subject
const faculties = ['Arts', 'Science', 'Technical', 'Commercial', 'Vocational']

//sub systems
const subSystems = ['Francophone', 'Anglo-saxon']

//levels
const levels = {
  french: ['Baccaluereat', 'Capieme'],
  english: ['Ordinary', 'Advanced'],
}

/* 
=================================
* Resources
==================================
*/
const categories = {}
const types = {}

export { faculties, levels, permissions, roles, subSystems }
