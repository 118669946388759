// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getDatabase } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getPerformance } from 'firebase/performance'
import { getStorage } from 'firebase/storage'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB_Z0bEP5VY-DFTqdv2RmzGpLWZFpzJbXE',
  authDomain: 'ket-academy-web-app-id.firebaseapp.com',
  databaseURL:
    'https://ket-academy-web-app-id-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ket-academy-web-app-id',
  storageBucket: 'ket-academy-web-app-id.appspot.com',
  messagingSenderId: '684482815070',
  appId: '1:684482815070:web:7d7f453f7724dc85fac0cf',
  measurementId: 'G-PGY589TP52',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const perf = getPerformance(app)
const analytics = getAnalytics(app)
const rtdb = getDatabase(app)
const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth(app)
auth.useDeviceLanguage()

export { analytics, auth, db, perf, rtdb, storage }
