import React from 'react'
import './index.scss'

const Banner = function ({ title, content }) {
  return (
    <section className="banner h-60 mt-24 px-4 md:px-16 flex justify-center items-center">
      <div className="flex flex-col justify-center items-center w-full h-full gap-3">
        <h3 className="text-5xl inline-block text-secondary font-black font-primary ">
          {title}
        </h3>
        <p className="inline-block w-full md:w-[80%] text-center text-accent">
          {content}
        </p>
      </div>
    </section>
  )
}

export default Banner
