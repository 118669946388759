import React from 'react'
import BreadCrumb from '../../BreadCrumb'
import './index.scss'

const DashboardHome = function () {
  return (
    <main className="dashboard">
      <BreadCrumb
        key={Math.random() * 1000}
        title={'Dashboard'}
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard',
            active: true,
          },
        ]}
      ></BreadCrumb>

      {/* <section className="courses-content">
        <div className="container">
          <div className="courses-profile">
            <div className="title"></div>
          </div>
        </div>
      </section> */}
    </main>
  )
}

export default DashboardHome
