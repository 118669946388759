import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

const BreadCrumb = function ({ title, slugs }) {
  return (
    <section className="bread_crumb">
      <h3 className="crumb_title">{title}</h3>

      <div className="crumb_slug">
        {slugs.map((slug, index) => (
          <React.Fragment key={`${slug.display}-${index}`}>
            <Link
              key={slug.display}
              to={slug.to}
              className={slug.active ? 'active' : ''}
            >
              {slug.display}
            </Link>
            <span key={index}>/ </span>
          </React.Fragment>
        ))}
        {/* <span>dashboard</span> <span>/</span> <span>profile</span> */}
      </div>
    </section>
  )
}

export default BreadCrumb
