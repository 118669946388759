import {
  faArrowLeft,
  faArrowRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

// import required modules

import './index.scss'

const Testimonials = () => {
  const mockdata = [
    {
      stars: 5,
      text: 'Add effects to your images online. Add effects to your images online. Add effects to your images online. Add effects to your images online.  Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Anushka kemuyeub',
      school: 'GBHS Etoug-Egbe',
    },
    {
      stars: 3,
      text: 'Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Kamaha Ronald',
      school: 'PGSS Baffousam',
    },
    {
      stars: 4,
      text: 'Add effects to your images online. Simple to use to your images online. Simple to use to your images online. Simple to use to your images online. Simple to use to your images online. Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Kinlo Ephriam',
      school: 'EHS Yaounde',
    },
    {
      stars: 5,
      text: 'Add effects to your images online. Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Nfor Glen',
      school: 'GHS Limbe',
    },

    {
      stars: 4,
      text: 'Add effects to your images online. Simple to use to your images online. Simple to use to your images online. Simple to use to your images online. Simple to use to your images online. Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Kinlo Ephriam',
      school: 'EHS Yaounde',
    },
    {
      stars: 5,
      text: 'Add effects to your images online. Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Nfor Glen',
      school: 'GHS Limbe',
    },

    {
      stars: 5,
      text: 'Add effects to your images online. Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Nfor Glen',
      school: 'GHS Limbe',
    },

    {
      stars: 5,
      text: 'Add effects to your images online. Simple to use, edit your photos with a few clicks. Recreate: Picture Editor. Add filters, and text to your photos for free. Crop and resize pictures',
      name: 'Nfor Glen',
      school: 'GHS Limbe',
    },
  ]
  const testimonialBox = useRef(null)
  const [testimonials, setTestimonials] = useState(mockdata)

  const testimonialEls = useRef(new Array())
  const scrollToNextPrev = (direction) => {
    const width = testimonialBox.current?.scrollWidth
    const scrollWidth = testimonialBox.current?.scrollLeft
    let nextItemPosition = scrollWidth + direction * 400 //+width of testimonial

    if (nextItemPosition > width) nextItemPosition = width
    if (nextItemPosition < 0) nextItemPosition = 0

    testimonialBox.current?.scrollTo({
      left: nextItemPosition,
      behavior: 'smooth',
    })

    console.log({ nextItemPosition })
  }

  const scroller = () => {
    let nextItemIndex,
      testimonialCount = testimonialEls.current.length
    for (let i = 0; i < testimonialCount; i++) {
      let element = testimonialEls.current[i]
      if (element.active) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        })
        nextItemIndex = (i + 1) % testimonialCount
        element.active = false
        break
      }
    }
    console.log({ testimonialEls, nextItemIndex })
    testimonialEls.current[nextItemIndex].active = true
  }

  useEffect(() => {
    setTimeout(scroller, 600)
  }, [testimonialEls])
  return (
    <section className="testimonials-section py-8  bg-primary block relative h-auto">
      <div>
        <h3 className="font-bold text-2xl text-center text-secondary">
          What Our Students Say
        </h3>
      </div>

      <div
        className="testimonials flex flex-row overflow-x-auto px-0 "
        ref={testimonialBox}
      >
        {testimonials.map(({ stars, text, name, school }, i) => (
          <Testimonial
            stars={stars}
            text={text}
            name={name}
            school={school}
            key={`testimonial-${i}`}
            active={i == 0}
            // ref={(element) => testimonialEls.current.push(element)}
          />
        ))}
      </div>
      <div className="controls text-center min-w-min mx-auto my-4">
        <button
          className="inline-flex w-10 h-10 p-3 mr-4 justify-center align-middle bg-secondary text-accent rounded-full shadow-xl"
          onClick={(_) => scrollToNextPrev(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        {Array(testimonials.length)
          .fill(null)
          .map((_, i) => (
            <span
              key={i}
              className="inline-block w-3 h-3 mx-2 rounded-full bg-secondary my-auto"
            ></span>
          ))}

        <button
          className="inline-flex w-10 h-10 p-3 ml-4  justify-center align-middle bg-secondary text-accent rounded-full shadow-xl"
          onClick={(_) => scrollToNextPrev(1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </section>
  )
}

const Testimonial = ({ stars, text, name, school }) => {
  return (
    <div className="bg-secondary py-2 px-4 testimonial">
      <div className="stars my-2 flex">
        {Array(stars)
          .fill(null)
          .map((_, i) => (
            <FontAwesomeIcon
              icon={faStar}
              className="text-accent mr-1"
              key={i}
            />
          ))}
        {Array(5 - stars)
          .fill(null)
          .map((_, i) => (
            <FontAwesomeIcon
              icon={faStar}
              className="text-neutral-alt-3  mr-1"
              key={i}
            />
          ))}
      </div>
      <p className="text">{text}</p>
      <h5 className="mt-2 text-neutral-alt-2 font-bold text-right">{name}</h5>
      <h6 className="font-normal text-right">{school}</h6>
    </div>
  )
}
export default Testimonials
