import {
  faBookAtlas,
  faCalendarDays,
  faChalkboardTeacher,
  faFolder,
  faGraduationCap,
  faSchool,
  faTableColumns,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import DashboardSideNavBar from '../../components/SideNav'
import Footer from '../Footer'
import DashboardHeader from '../Header'

const links = [
  { text: 'Dashboard', link: '/admin/dashboard/', faIcon: faTableColumns },
  {
    text: 'My Profile',
    link: '/admin/dashboard/profile',
    faIcon: faUserCircle,
  },
  { text: 'Courses', link: '/admin/dashboard/courses', faIcon: faBookAtlas },
  { text: 'Resources', link: '/admin/dashboard/resources', faIcon: faFolder },

  {
    text: 'Schedule',
    link: '/admin/dashboard/schedule',
    faIcon: faCalendarDays,
  },
  {
    text: 'Academy',
    link: '/admin/dashboard/academy',
    faIcon: faSchool,
  },
  {
    text: 'Students',
    link: '/admin/dashboard/students',
    faIcon: faGraduationCap,
  },
  {
    text: 'Ambassadors',
    link: '/admin/dashboard/instructors',
    faIcon: faChalkboardTeacher,
  },
]
const AdminDashboard = function ({ children }) {
  const [sideBarShowing, setSideBarShowing] = useState(false)
  const toggleSidebar = () =>
    setSideBarShowing((sideBarShowing) => !sideBarShowing)

  return (
    <>
      <DashboardHeader onNavToggle={toggleSidebar} />
      <DashboardSideNavBar
        sideBarShowing={sideBarShowing}
        toggleSidebar={toggleSidebar}
        linkItems={links}
      />
      <Outlet></Outlet>
      {children}
      <Footer />
    </>
  )
}

export default AdminDashboard
