import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import logo from '../../assets/images/academy/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faChevronRight,
  faEnvelope,
  faPaperPlane,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
  const [toTop, setToTop] = useState(false)
  useEffect(() => {
    const onScroll = (e) => setToTop(window.scrollY > 200)
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
  return (
    <footer className="footer__home">
      <div className="footer-top">
        <div className="container">
          <div>
            <div className="footer-contact">
              <Link to="/">
                <div className="logo mb-4">
                  <img src={logo} alt="KET Academy logo" />
                </div>
              </Link>
              <p>
                OD Chappelle d'Obili
                <br />
                Yaounde, CMR8687
                <br />
                Cameroon
              </p>
              <br />
              <p className="my-2">
                <FontAwesomeIcon icon={faPhone} className="w-5 h-5 mr-1" /> +237
                677 777 777
              </p>
              <p className="text-ellipsis leading-none">
                <FontAwesomeIcon icon={faEnvelope} className="w-5 h-5 mr-1" />{' '}
                ketacademy1@gmail.com
              </p>
            </div>

            <div className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/tos">Terms of Service</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="footer-links">
              <h4>Interact</h4>

              <ul>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/stories">Share your story</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/reports">Our Yearly Report</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/team">Our team</Link>
                </li>
                <li>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Link to="/donations">Donate</Link>
                </li>
              </ul>
            </div>

            <div className="footer-newsletter">
              <h4>Join Our Newsletter</h4>
              <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p>
              <form action="" method="post">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                />
                <button type="submit">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer-bottom">
        <div className="container">
          <div className="copyright">
            &copy;
            <strong>
              <span>KET Academy</span>,
            </strong>
            {new Date().getFullYear()}. All Rights Reserved
          </div>

          <div className="social-links">
            <Link to="https://www.twitter.com/AcademyKet" target="_blank">
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
            <Link
              to="https://www.facebook.com/KET-AcademyOrientation-107170228312584/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link
              to="https://www.linkedin.com/company/77005269"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
            <Link to="https://www.instagram.com/ket.academy/" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link to="#" target="_blank">
              <FontAwesomeIcon icon={faWhatsapp} />
            </Link>
            <Link to="https://www.youtube.com/c/KETAcademy" target="_blank">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
          </div>
        </div>
      </div>
      <Link
        to="#"
        className={`back-to-top ${!toTop ? 'hide' : ''}`}
        onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Link>
    </footer>
  )
}

export default Footer
