export const routes = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'Services',
    path: '/services',
  },
  {
    name: 'Team',
    path: '/team',
  },
  {
    name: 'Library',
    path: '/library',
  },
  {
    name: 'Stories',
    path: '/testimonials',
  },
]
