// Importing necessary React components and assets
import React, { useMemo, useState } from 'react'
// import image from '../../../assets/images/donation/Sponsor-bro.png'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'

// Component for rendering individual donation buttons
const DonationButton = ({ amount, isActive, onClick }) => (
  <button
    type="button"
    onClick={() => onClick(amount)}
    className={`py-2 px-4 rounded ${
      isActive ? 'bg-blue-700 text-white' : 'bg-gray-200'
    }`}
    aria-label={`Donate ${amount} dollars`}
  >
    ${amount}
  </button>
)

// Component for rendering input fields with dynamic IDs, labels, and placeholders
const InputField = ({ id, label, type, placeholder }) => (
  <div
    className={`w-full ${id !== 'phone' ? 'md:w-1/2' : ''} px-3 mb-6 md:mb-0`}
  >
    <label
      className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
      htmlFor={id}
    >
      {label}
    </label>
    <input
      className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
      id={id}
      type={type}
      placeholder={placeholder}
      aria-label={label}
    />
  </div>
)

// Main component for the Donations page
const Donations = () => {
  const [donationAmount, setDonationAmount] = useState('')
  const donationOptions = useMemo(() => ['25', '55', '75', '150'], [])

  // Function to handle setting the donation amount
  const handleSetAmount = (amount) => {
    if (donationAmount !== amount) {
      setDonationAmount(amount)
    }
  }

  return (
    <>
      <Banner title="Support our cause Today" />
      <div className="flex flex-col items-center p-5 text-center">
        <h1 className="text-4xl font-bold text-yellow-500 mb-2">Donation</h1>
        <p className="text-gray-600 text-xl mb-4">
          Join Us in Making a Difference. Donate Today!
        </p>
        <button
          type="button"
          className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-3 px-6 rounded mb-5 transition duration-300 ease-in-out"
          onClick={() => (window.location.href = '#donate')}
        >
          Start Donating
        </button>
        {/* <img
          src={
            'https://annamrita.org/wp-content/uploads/2023/02/Why-should-you-donate-to-the-education-of-impoverished-children_.jpg'
          }
          alt="Donation"
          className="w-full max-w-4xl mx-auto block shadow-lg"
        /> */}
      </div>
      <div className="flex flex-col items-center p-5 bg-white">
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold">
            Choose the amount you wish to donate
          </h2>
          <div className="flex items-left justify-center space-x-2 mt-4">
            {donationOptions.map((amount) => (
              <DonationButton
                key={amount}
                amount={amount}
                isActive={donationAmount === amount}
                onClick={handleSetAmount}
              />
            ))}
            <input
              type="number"
              placeholder="Other amount"
              className="border rounded py-2 px-4"
              onChange={(e) => handleSetAmount(e.target.value)}
              aria-label="Enter other donation amount"
            />
          </div>
        </div>
        <form className="w-full max-w-lg">
          <div className="flex flex-wrap -mx-3 mb-6">
            <InputField
              id="first-name"
              label="First Name"
              type="text"
              placeholder="Jane"
            />
            <InputField
              id="last-name"
              label="Last Name"
              type="text"
              placeholder="Doe"
            />
            <InputField
              id="email"
              label="Email"
              type="email"
              placeholder="you@example.com"
            />
            <InputField
              id="phone"
              label="Phone Number"
              type="text"
              placeholder="(555) 555-5555"
            />
          </div>
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
            >
              Donate ${donationAmount || '1'}
            </button>
          </div>
          <div className="mt-6">
            <h3 className="text-xl font-bold mb-2">Checkout</h3>
            <p className="text-lg mb-4">
              Donate with your preferred payment method:
            </p>
            <div className="flex space-x-4">
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                <i className="fab fa-paypal mr-2 text-lg"></i>Paypal
              </button>
              <button
                type="button"
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded flex items-center"
              >
                <i className="far fa-credit-card mr-2 text-lg"></i>Credit Card
              </button>
              <button
                type="button"
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded flex items-center"
              >
                <i className="fas fa-university mr-2 text-lg"></i>Bank Transfer
              </button>
              <button
                type="button"
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded flex items-center"
              >
                <i className="fas fa-mobile-alt mr-2 text-lg"></i>MoMo
              </button>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </>
  )
}
export default Donations
