import React from 'react'
import * as BoostrapIcon from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

import Footer from '../../../components/Footer'
import './index.scss'

const Team = () => {
  return (
    <>
      <section className="banner h-96 mt-24 px-4 md:px-16 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center w-full h-full gap-3">
          <h3 className="text-5xl inline-block text-secondary font-black font-primary ">
            Who we are.
          </h3>
          <p className="inline-block w-full md:w-[80%] text-center text-white">
            Get to know the faces behind our academy – from instructors to
            content creators – and discover the people who are dedicated to
            making your learning experience exceptional. We are committed to
            your success, and we're excited to be part of your educational
            adventure
          </p>
        </div>
      </section>

      <h2 className="text-3xl font-bold text-center mt-5">
        Meet the Management
      </h2>
      <div className="mt-12 team flex justify-around flex-wrap">
        <CoreTeamCard></CoreTeamCard>
        <CoreTeamCard></CoreTeamCard>
        <CoreTeamCard></CoreTeamCard>
        <CoreTeamCard></CoreTeamCard>
      </div>
      <h3 className="text-3xl font-bold text-center mt-5">Meet the Team</h3>

      <div className="mt-12 team flex justify-around flex-wrap">
        <MemberCard></MemberCard>
        <MemberCard></MemberCard>
        <MemberCard></MemberCard>
        <MemberCard></MemberCard>
        <MemberCard></MemberCard>
        <MemberCard></MemberCard>
        <MemberCard></MemberCard>
      </div>
      <Footer />
    </>
  )
}

const CoreTeamCard = function () {
  return (
    <div className="core-member">
      <div className="container">
        <div className="avatar mx-2">
          <img src="https://picsum.photos/200" alt="Some member" />
        </div>
        <div className="info mx-2">
          <h4 className="text-primary font-bold mb-1">Kinlo Ephriam Tangiri</h4>
          <p>Bsc, Computer Science.</p>
          <p>Constructor University, 2026.</p>
          <hr className="w-16 my-4 h-1 bg-accent-alt outline-none border-none"></hr>
          <span className="text-primary my-2 text-lg"> Founder & CEO</span>
        </div>
      </div>
    </div>
  )
}

const MemberCard = function () {
  return (
    <div className="member">
      <div className="container">
        <div className="avatar mx-2">
          <img src="https://picsum.photos/200" alt="Some member" />
        </div>
        <div className="info mx-2">
          <h4 className="text-primary font-bold mb-1">Kinlo Ephriam Tangiri</h4>
          <p>Bsc, Electrical Engineering.</p>
          <p>Constructor University, 2026.</p>
          <hr className="w-16 my-2 h-1 bg-accent-alt outline-none border-none"></hr>
          <span className="text-primary my-2"> HOD, Computer Science</span>
          <div className="depts my-2 mx-auto">
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-4 py-1 rounded cursor-pointer">
              Chem
            </span>
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-4 py-1 rounded cursor-pointer">
              Phy
            </span>
            <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-4 py-1 rounded cursor-pointer">
              CSC
            </span>
          </div>
          <div className="contacts">
            <Link to="https://www.twitter.com/home" className="twitter">
              <BoostrapIcon.Twitter className="text-2xl text-primary" />
            </Link>
            <Link
              to="https://www.facebook.com/kinlo.ephraim/"
              className="facebook"
            >
              <BoostrapIcon.Facebook className="text-2xl text-primary" />
            </Link>
            <Link
              to="https://www.instagram.com/kinloephraim/"
              className="instagram"
            >
              <BoostrapIcon.Instagram className="text-2xl text-primary" />
            </Link>
            <Link
              to="https://www.linkedin.com/in/kinlo-ephraim-tangiri-a70113218/"
              className="linkedin"
            >
              <BoostrapIcon.Linkedin className="text-2xl text-primary" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
