import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const NotFound404Page = () => {
  return (
    <div className="error-main flex flex-col justify-center items-center px-8">
      <div class="error-content flex flex-col justify-around items-center px-8">
        <h1 className="block text-5xl text-accent font-black drop-shadow-lg">
          Not Found
        </h1>

        <h3 className="block text-lg text-primary font-secondary font-normal text-center py-4 drop-shadow">
          The page your are looking for does not exist on this site.
        </h3>

        <Link
          className="to-home text-sm text-secondary font-thin m-6 flex items-center justify-center"
          to="/"
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="pr-4 arrow-back w-4 inline-block"
          />
          Back to Home
        </Link>
      </div>
    </div>
  )
}

export default NotFound404Page
