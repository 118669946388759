import React from 'react'
import './index.scss'
import * as BoostrapIcon from 'react-bootstrap-icons'

const WhyUs = () => {
  return (
    <section className="whyus-section py-8 block relative h-auto">
      <div>
        <h3 className="font-bold text-2xl text-center text-primary-alt">
          Why Choose Us.
        </h3>
      </div>
      <div className="reasons my-8 flex flex-row justify-between flex-wrap">
        <div className="row flex flex-row justify-around items-center w-100">
          <div className="reason flex flex-row justify-center m-4 ">
            <span className="icon mr-4 flex  justify-center items-center rounded-lg">
              <BoostrapIcon.PersonCheck className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Expert Tutors
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
                We have the best tutors using the latest technology software and
                gadgets to guarantee a top-notch learning experience.
              </p>
            </div>
          </div>
          <div className="reason flex flex-row justify-center m-4">
            <span className="icon mr-4 flex  justify-center items-center rounded-lg">
              <BoostrapIcon.Book className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Expert Course Material
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
                We provide you with expert-level guidance needed to chart your
                academic and career path according to your aspirations and
                strengths.
              </p>
            </div>
          </div>
        </div>
        <div className="row flex flex-row justify-around items-center">
          <div className="reason flex flex-row justify-center items-center m-4">
            <span className="icon mr-4 flex  justify-center items-center rounded-lg">
              <BoostrapIcon.PersonVideo3 className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Real-time Lectures
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
                We have the best tutors using the latest technology software and
                gadgets to guarantee a top-notch learning experience.
              </p>
            </div>
          </div>
          <div className="reason flex flex-row justify-center m-4">
            <span className="icon mr-4 flex justify-center items-center rounded-lg">
              <BoostrapIcon.ArrowLeftRight className="text-6xl text-primary" />
            </span>
            <div>
              <h3 className="text-left text-xl font-semibold my-1">
                Mentorship and Orientation
              </h3>
              <p className="text-neutral-1 align-text-bottom font-light">
                We provide you with expert-level guidance needed to chart your
                academic and career path according to your aspirations and
                strengths.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials flex flex-row overflow-x-auto px-0 "></div>
    </section>
  )
}

export default WhyUs
