import {
  faCamera,
  faExclamationTriangle,
  faMinusCircle,
  faPlus,
  faSync,
  faTrashAlt,
  faUserEdit,
  faUserLock,
  faUserXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { faculties } from '../../../utils/constants'
import BreadCrumb from '../../BreadCrumb'
import './styles.scss'

import dummy from '../../../assets/images/sample/Kinlo.jpg'

const Profile = function () {
  //bio, full name, preferred name
  const [user, setUser] = useState(null)
  const [bio, setBio] = useState(null)

  //about education
  const [region, setRegion] = useState(null)
  const [city, setCity] = useState(null)
  const [edSystem, setEdSystem] = useState(null)
  const [specialty, setSpecialty] = useState(null)
  const [level, setLevel] = useState(null)
  const [school, setSchool] = useState(null)
  const [subjects, setSubjects] = useState([])

  useEffect(() => {})

  return (
    <main className="profile">
      <BreadCrumb
        key={Math.random() * 1000}
        title={'Profile'}
        slugs={[
          {
            display: 'Dashboard',
            to: '/dashboard',
            active: false,
          },

          {
            display: 'Profile',
            to: '/dashboard/profile',
            active: true,
          },
        ]}
      ></BreadCrumb>

      <section className="profile-content">
        <div className="container">
          <div className="section-profile">
            <div className="title">
              <h2>Your Personal Profile</h2>
              <p>Your profile contains your basic information</p>
            </div>
            <div className="content">
              <div className="profile-pic">
                <h4>Profile Picture</h4>
                <div>
                  <img src={dummy} alt="Profile" />
                  <span>
                    <button>
                      <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                    </button>
                    <button>
                      <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                    </button>
                  </span>
                </div>
              </div>

              <div className="bio">
                <h4>Personal Bio</h4>
                <div>
                  <textarea
                    name="user-bio"
                    rows={5}
                    placeholder="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Molestiae cum minima vel dicta,  dolor soluta quaerat explicabo. Aspernatur?"
                  ></textarea>
                </div>
              </div>
            </div>
            <button className="my-2">
              <FontAwesomeIcon icon={faUserEdit} className="pr-2" />
              Update Profile
            </button>
          </div>

          <div className="section-student">
            <div className="title">
              <h2>
                Your KET Academy <strong>Student</strong> Profile
              </h2>
              <p>
                Your personal student information for a personalised experience.
              </p>
            </div>
            <div className="content">
              <div className="ket-id-card">
                <h4>Your KET ID card</h4>
                <div
                  className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 my-4"
                  role="alert"
                >
                  <p className="font-bold">Incomplete profile</p>
                  <p>
                    Your ID Card will be created after your verify your email
                    and complete setting up this profile page
                  </p>
                </div>
              </div>

              <div className="inputs names">
                <div>
                  <label htmlFor="">Full Name</label>
                  <input type="text" name="" id="" />
                </div>
                <div>
                  <label htmlFor="">Prefered/First Name</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    maxLength={8}
                    minLength={3}
                  />
                </div>
              </div>

              <div className="inputs school-info">
                <div>
                  <label htmlFor="">Region</label>
                  <p>Select from dropdown</p>
                  <select name="" id=""></select>
                </div>
                <div>
                  <label htmlFor="">City</label>
                  <p>Select from dropdwon</p>
                  <select name="" id="" className="px-4">
                    <option>Yello</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="">School</label>
                  <p>
                    If you school is absent from the options, report to the
                    academy.
                  </p>
                  <select name="" id=""></select>
                </div>
              </div>

              <div className="inputs school-level">
                <div>
                  <label htmlFor="">Education sub-System</label>
                  <p>French / English</p>
                  <select name="" id=""></select>
                </div>
                <div>
                  <label htmlFor="">Specialty</label>
                  <p>Arts / Sciences / Commercial / Technical / Vocational</p>
                  <select name="" id="">
                    Select specialty
                    <option value="">-- Select your specialty --</option>
                    {faculties.map((item, ind) => (
                      <option value={item} key={`specialty-${ind}`}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="">Level</label>
                  <p>Ordinary / Advanced</p>
                  <select name="" id=""></select>
                </div>
              </div>

              <button className="button my-2">
                <FontAwesomeIcon icon={faSync} className="mr-2" /> Update
                Student Info
              </button>

              <div className="subjects">
                <h4 className=""> Your Subjects </h4>
                <div>
                  <span className="px-2 py-1 subject border border-primary rounded text-sm mr-3 my-2">
                    Mathematics with Mechanics
                    <button className="text-error px-1">
                      <FontAwesomeIcon
                        className=" group-hover-[icon]:shadow-lg text-lg pl-4"
                        icon={faMinusCircle}
                      />
                    </button>
                  </span>

                  <span className="px-2 py-1 subject border border-primary rounded text-sm mr-3 my-2">
                    Further Mathematics
                    <button className="text-error px-1">
                      <FontAwesomeIcon
                        className=" group-hover-[icon]:shadow-lg text-lg pl-4"
                        icon={faMinusCircle}
                      />
                    </button>
                  </span>

                  <span className="px-2 py-1 subject border border-primary rounded text-sm mr-3 my-2">
                    Chemistry
                    <button className="text-error px-1">
                      <FontAwesomeIcon
                        className=" group-hover-[icon]:shadow-lg text-lg pl-4"
                        icon={faMinusCircle}
                      />
                    </button>
                  </span>
                </div>
                <button className="button" title="Add Subject">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          </div>

          <div className="section-account">
            <div className="container">
              <div className="title">
                <h2>
                  Your KET Academy <strong>Account</strong> Settings
                  <p>Manage your personal account</p>
                </h2>
              </div>
              <div className="content">
                <div>
                  <h4 className=""> Email </h4>
                  <div>
                    <p className="text-sm py-2">
                      Change your email here. Note that, after changing your
                      email your previous email is no longer associated with
                      this account.
                    </p>
                    <label>New Email</label>
                    <input type="email" name="" id="" />
                    <button className="bg-accent">Update Email</button>
                  </div>
                  <h4 className=""> Password </h4>
                  <div>
                    <p className="text-sm py-2">
                      Note that, after changing your password, you will be
                      logged out from all other devices.
                    </p>
                    <label>Old Password</label>
                    <input type="email" name="" id="" />
                    <label>New Password</label>
                    <input type="email" name="" id="" />
                    <button className="bg-accent">Change Password</button>
                  </div>

                  <h4 className="">
                    Account
                    <FontAwesomeIcon
                      className="mx-2 text-red-400"
                      icon={faExclamationTriangle}
                    />
                  </h4>
                  <div>
                    <div>
                      <p className="text-sm">
                        Once your account is deleted, all your data will be
                        lost.
                      </p>
                      <button className="bg-error block my-4">
                        <FontAwesomeIcon className="mx-2" icon={faUserXmark} />
                        {/* <BoostrapIcon.Account /> */}
                        Delete My Account
                      </button>
                    </div>
                    <div>
                      <p className="text-sm">
                        Instead of deleting your account, you might want to
                        disable it instead.
                      </p>
                      <button className="bg-info block my-4">
                        <FontAwesomeIcon className="mx-2" icon={faUserLock} />
                        {/* <BoostrapIcon.Account /> */}
                        Disable My account.
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Profile
