import {
  faBullseye,
  faExchange,
  faEye,
  faHeartPulse,
  faRegistered,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'
import './index.scss'

const AboutUs = () => {
  return (
    <>
      <Banner
        title={'What We Are.'}
        content={`KET academy is a non-profit organisation that seeks to reform
      education in Cameroon. KET academy orientates, assists, mentors and
      support student in any area of academic purpose pursuit that is of
      benefit to the community.`}
      />

      <div className="mvp mt-12 mb-4 mx-8">
        <div className=" flex flex-col lg:flex-row justify-center items-center gap-4">
          <div className="item flex lg:w-[420px]  bg-primary rounded-xl w-full">
            <div className="left flex items-center justify-center  px-12 py-4  border-r-2 border-secondary w-1/5">
              <FontAwesomeIcon
                icon={faBullseye}
                className="h-12 font-extrabold text-accent"
              />
              {/* <span className="text-3xl font-extrabold font-primary text-accent">
                01
              </span> */}
            </div>
            <div className="right block  px-4 py-4 w-4/5">
              <h3 className="text-accent text-xl pb-2 font-bold">
                Our Mission
              </h3>
              <p className="text-secondary">
                We are wholly and fully committed to training youths with the
                skills to contribute to the transformation and re-designing of
              </p>
            </div>
          </div>

          <div className="item flex lg:w-[420px]  bg-primary rounded-xl w-full ">
            <div className="left flex items-center justify-center  px-12 py-4  border-r-2 border-secondary w-1/5">
              <FontAwesomeIcon
                icon={faEye}
                className="h-12 font-extrabold text-accent"
              />
              {/* <span className="text-3xl font-extrabold font-primary text-accent">
                02
              </span> */}
            </div>
            <div className="right block  px-4 py-4 w-4/5">
              <h3 className="text-accent text-xl pb-2 font-bold">Our Vision</h3>
              <p className="text-secondary">
                We are wholly and fully committed to training youths with the
                skills to contribute to the transformation and re-designing of
              </p>
            </div>
          </div>

          <div className="item flex lg:w-[420px]  bg-primary rounded-xl w-full ">
            <div className="left flex items-center justify-center  px-12 py-4  border-r-2 border-secondary w-1/5">
              <FontAwesomeIcon
                icon={faHeartPulse}
                className="h-12 font-extrabold text-accent"
              />
              {/* <span className="text-3xl font-extrabold font-primary text-accent">
                03
              </span> */}
            </div>
            <div className="right block  px-4 py-4 w-4/5">
              <h3 className="text-accent text-xl pb-2 font-bold">
                Our Passion
              </h3>
              <p className="text-secondary">
                We are wholly and fully committed to training youths with the
                skills to contribute to the transformation and re-designing of
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Our History as timeline --> */}
      <div class="py-4 flex flex-col justify-center sm:py-12 my-4">
        <h1 className="text-4xl text-primary text-center font-extrabold my-2">
          Our History
        </h1>
        <div class="py-3 sm:max-w-4xl sm:mx-auto w-full px-3">
          <div class="relative text-gray-700 antialiased text-sm font-semibold">
            {/* <!-- Vertical bar running through middle --> */}
            <div class=" sm:block w-1 bg-primary absolute h-full left-1/2 transform -translate-x-1/2 z-[-99]"></div>

            {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
            <div class="mt-6 sm:mt-0 sm:mb-12 w-full">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-start w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pr-8">
                    <div class="p-4 bg-white rounded shadow-md">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-pink-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 font-secondary">
                          August 2023
                        </span>
                      </h3>
                      KET Academy officially registered as an organisation in
                      cameroon.
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>

            {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-end w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pl-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-blue-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300 font-secondary">
                          June 2022
                        </span>
                      </h3>
                      My life got flipped turned upside down,
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faExchange}
                    className="h-5 text-primary z-10 block relative"
                  />
                </div>
              </div>
            </div>

            {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-start w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pr-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-blue-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300 font-secondary">
                          January 2021
                        </span>
                      </h3>
                      And I'd like to take a minute, just sit right there,
                    </div>
                  </div>
                </div>
                <div class="rounded-full  bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>

            {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
            <div class="mt-6 sm:mt-0">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-end w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pl-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-blue-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300 font-secondary">
                          June 2022
                        </span>
                      </h3>
                      I'll tell you how I became the Prince of a town called Bel
                      Air.
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>
            {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-start w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pr-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-blue-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300 font-secondary">
                          January 2021
                        </span>
                      </h3>
                      And I'd like to take a minute, just sit right there,
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>

            {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
            <div class="mt-6 sm:mt-0">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-end w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pl-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-blue-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-900 dark:text-gray-300 font-secondary">
                          June 2022
                        </span>
                      </h3>
                      I'll tell you how I became the Prince of a town called Bel
                      Air.
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>
            {/* <!-- Left section, set by justify-start and sm:pr-8 --> */}
            <div class="mt-6 sm:mt-0 sm:mb-12">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-start w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pr-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-blue-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300 font-secondary">
                          January 2021
                        </span>
                      </h3>
                      And I'd like to take a minute, just sit right there,
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>

            {/* <!-- Right section, set by justify-end and sm:pl-8 --> */}
            <div class="mt-6 sm:mt-0">
              <div class="flex flex-col sm:flex-row items-center">
                <div class="flex justify-end w-full mx-auto items-center">
                  <div class="w-full sm:w-1/2 sm:pl-8">
                    <div class="p-4 bg-white rounded shadow">
                      <h3 className="text-lg font-bold mb-1 text-accent sm:text-center">
                        <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300 font-secondary">
                          June 2022
                        </span>
                      </h3>
                      I'll tell you how I became the Prince of a town called Bel
                      Air.
                    </div>
                  </div>
                </div>
                <div class="rounded-full bg-white border-accent border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <FontAwesomeIcon
                    icon={faRegistered}
                    className="h-5 text-primary"
                  />
                </div>
              </div>
            </div>
            {/* End of timeline */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default AboutUs
