import React from 'react'
import './index.scss'

const AdminDashboardHome = function () {
  return <main></main>
}

const StatisticCard = function () {
  return <div></div>
}

const PostCard = function () {
  return <section></section>
}
export default AdminDashboardHome
