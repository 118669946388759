import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
import 'sweetalert2/src/sweetalert2.scss'
import './index.scss'

const CustomSwal = Swal.mixin({
  customClass: {
    popup: 'pop-up',
    title: 'alert-title',
    text: 'alert-text',
    icon: 'alert-icon',
    input: 'alert-input',
    validationMessage: 'alert-message',
    confirmButton: 'confirm-button',
    cancelButton: 'cancel-button',
    closeButton: 'close-button',
    denyButton: 'deny-button',

    // container: '...',
    // popup: '...',
    // header: '...',
    // title: '...',
    // closeButton: '...',
    // icon: '...',
    // image: '...',
    // htmlContainer: '...',
    // input: '...',
    // inputLabel: '...',
    // validationMessage: '...',
    // actions: '...',
    // confirmButton: '...',
    // denyButton: '...',
    // cancelButton: '...',
    // loader: '...',
    // footer: '....',
    // timerProgressBar: '....',
  },
  buttonsStyling: false,
})

const Alert = withReactContent(CustomSwal)

export default Alert
