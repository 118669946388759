import React from 'react'
import Banner from '../../../components/Banner'
import Footer from '../../../components/Footer'

// Example of a reusable Checkbox component
const Checkbox = ({ label }) => (
  <label className="block text-sm">
    <input type="checkbox" className="form-checkbox h-4 w-4 text-blue-600" />
    <span className="ml-2 text-gray-700">{label}</span>
  </label>
)

function Library() {
  // Example of using useState
  // const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <Banner
        title="Library"
        content="Explore a wide range of subjects and topics, and find the perfect course to kickstart your educational journey no matter your education level"
      />

      <div className="bg-white text-center py-10 flex justify-between items-center">
        <div className="w-full">
          <h1 className="text-4xl font-bold mb-2 text-center">
            Get Free Access to Our Courses
          </h1>
          <h2 className="text-2xl font-semibold text-orange-500 text-center">
            ON ALL DEVICES
          </h2>
          <p className="mt-4 text-center">
            Access all our free videos and PDF resources
          </p>
          <div className="mt-6 flex justify-center">
            <input
              type="text"
              placeholder="Enter Course Title"
              className="border-2 border-primary rounded-lg px-4 py-3 w-1/3 shadow-lg"
            />
            <button className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-opacity-80 transition-colors ml-2">
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 p-6">
        <h1 className="text-4xl font-semibold text-orange-500 text-center">
          Browse Courses
        </h1>
        <p className="text-center text-md mb-4">
          Explore a wide range of subjects and topics, and find the perfect{' '}
          <br /> course to kickstart your educational journey
        </p>
        <div className="pt-10"></div>

        <div className="flex">
          <div className="w-1/4 pr-2">
            <h1 className="font-semibold mb-1 text-lg">Tags</h1>
            <div className="mb-2">
              <h4 className="font-semibold mb-1">Class Types</h4>
              <label className="block text-sm">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Lower Sixth</span>
              </label>
              <label className="block text-sm">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Upper Sixth</span>
              </label>
            </div>
            <div className="mb-2">
              <h4 className="font-semibold mb-1">Category</h4>
              <label className="block text-sm">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="ml-2 text-gray-700">PDF</span>
              </label>
              <label className="block text-sm">
                <input
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-blue-600"
                />
                <span className="ml-2 text-gray-700">Video</span>
              </label>
            </div>
            <div>
              <h4 className="font-semibold mb-1">Subject</h4>
              <Checkbox label="Mathematics" />
              <Checkbox label="Chemistry" />
              <Checkbox label="Biology" />
              <Checkbox label="Literature" />
              <Checkbox label="History" />
              <Checkbox label="English Language" />
              <Checkbox label="Physics" />
              <Checkbox label="Food & Nutrition" />
            </div>
          </div>

          <div className="w-3/4">
            <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-3">
              {Array.from({ length: 8 }).map((_, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow p-2 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-md"
                >
                  <div className="p-2">
                    <h4 className="text-lg font-semibold">Course Name</h4>
                    <p className="text-xs text-gray-600">
                      Course Instructor: Name
                    </p>
                    <p className="text-xs text-gray-600">Duration: ~40 MINS</p>
                  </div>
                </div>
              ))}
              <div className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold rounded-lg shadow flex items-center justify-center text-lg p-2 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-md">
                See All Courses
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default Library
