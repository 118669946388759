import React from 'react'
import Footer from '../../../components/Footer'
import { Hero, Partners, Testimonials, WhyUs } from '../../../components/Home'
import './styles.scss'

const HomePage = () => {
  return (
    <>
      <Hero />
      <WhyUs />
      <Testimonials />
      <Partners />
      <Footer />
    </>
  )
}

export default HomePage
